@font-face {
  font-family: 'StencilFont';
  src: url('../public/fonts/Fegan.ttf') format('truetype'),
       url('../public/fonts/Hand Stencil.ttf') format('wtruetypeoff'),
       url('../public/fonts/BndyYlwHS.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --bg-color: hsla(37, 30%, 92%, 1);
  --typewriterSpeed: 2s;
  --typewriterCharacters: 18;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;  
  display: block;
  place-content: center;
  text-align: center;
  /* background: var(--bg-color);   */  
}

h3 {
  font-size: clamp(.5rem, 1.5vw + .5rem, 1rem);
  position: relative;
  font-family: "Source Code Pro", monospace;  
  width: "300px";
  margin: auto;
}

h3::before,
h3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* h3::before {  
  background: var(--bg-color);
  animation: typewriter var(--typewriterSpeed)
    steps(var(--typewriterCharacters)) 1s forwards;
}

h3::after {
  width: 0.125em;
  background: black;
  animation: typewriter var(--typewriterSpeed)
      steps(var(--typewriterCharacters)) 1s forwards,
    blink 750ms steps(var(--typewriterCharacters)) infinite;
} */

.subtitle {
  color: hsl(0 0% 0% / 0.7);
  font-size: 2rem;
  font-weight: 400;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeInUp 2s ease calc(var(--typewriterSpeed) + 2s) forwards;
}

.logo-container {
  position: relative;
  animation: dropInLogo 6s ease-in-out forwards;  
}

.logo {  
  width: 400px;
  height: 400px;  
}

.main-container {
  background-size: cover;
  background-position: center;
}

.main-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropInLogo {
  from {top: -300px;}
  to {top: 10px;}
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}

.title {
  margin-top: 20px;
  text-align: center;
}

tr {
  height: 40px !important;
}

th {
  max-width: 125px;
  min-width: 125px;
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
}

td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
}

/* Cursor for pen tool */
.canvas-cursor-pen {
  cursor: "/eraser.png", auto; /* Specify the path to your custom cursor image */
}

/* Cursor for eraser tool */
.canvas-cursor-eraser {
  cursor: "/eraser.png", auto; /* Specify the path to your custom cursor image */
}

.canvas-cursor-text {
  cursor: pointer; /* Specify the path to your custom cursor image */
}

.penicon {
  cursor: url("/public/penicon.png"), auto;
}
